import React from "react"
import styled from "styled-components"
import media from "../styles/media"
import SEO from "../components/common/SEO"
import { graphql } from "gatsby"
import { GridContainer, Layout } from "../components/common/"
import { JobPosting } from "../components/employment"

const Wrapper = styled(GridContainer)`
  padding-bottom: 100px;

  ${media.tablet`
    padding-top: 126px;
    padding-bottom: 60px;
  `}
`
const PageDescription = styled.h1`
  font-weight: normal;

  font-family: StanleyRegular, serif;
  grid-column: 1 / span 4;
  grid-row: 1;
  margin: 0;
  line-height: 52px;
  font-size: 30px;

  ${media.tablet`
    font-size: 45px;
    grid-column: 1 / span 10;
  `}
`

const Disclaimer = styled.p`
  font-size: 16px;
  color: ${props => props.theme.lightGrey};
  line-height: 30px;
  font-family: "neue-haas-grotesk-text", sans-serif;
  grid-column: 1 / span 4;
  margin: 0;
  padding-bottom: 4px;

  ${media.tablet`
    grid-column: 1 / span 7;
  `}
`

const Employment = ({ data }) => {
  const employmentPageContent = data.allContentfulEmploymentPage.edges[0].node
  const pageDescription = employmentPageContent.pageDescription.pageDescription
  const disclaimer = employmentPageContent.disclaimer.disclaimer
  const jobListings = employmentPageContent.jobListings

  return (
    <Layout headerTheme="dark">
      <SEO
        title="Columbia Artists - Employment"
        path="/employment"
        description={
          employmentPageContent.employmentPageMetaContent
            ? employmentPageContent.employmentPageMetaContent.metaDescription
                .metaDescription
            : undefined
        }
      />
      <Wrapper>
        <PageDescription>{pageDescription}</PageDescription>
      </Wrapper>

      {jobListings.map((job, i) => {
        const title = job.title
        const description = job.description.description
        const requirements = job.requirements.childMarkdownRemark.html

        return (
          <JobPosting
            title={title}
            description={description}
            requirements={requirements}
            active={i === 0}
          />
        )
      })}
      <Wrapper>
        <Disclaimer>{disclaimer}</Disclaimer>
      </Wrapper>
    </Layout>
  )
}

export default Employment

export const pageQuery = graphql`
  {
    allContentfulEmploymentPage {
      edges {
        node {
          employmentPageMetaContent {
            metaDescription {
              metaDescription
            }
          }
          title
          pageDescription {
            pageDescription
          }
          disclaimer {
            disclaimer
          }
          jobListings {
            ...ContentfulEmploymentEntry
          }
        }
      }
    }
  }
`
