import React, { useState } from "react"
import styled from "styled-components"
import media from "../../styles/media"
import { GridContainer } from "../common/GridContainer"
import { PlusIcon, MinusIcon, RuleLine } from "../common"

const SectionWrapper = styled(GridContainer)`
  display: ${props => (props.show ? "grid" : "none")};
  margin-top: 10px;
`

const Section = styled.div`
  grid-column: 1 / span 4;

  ${media.tablet`
    margin-top: 15px;
    grid-column: 1 / span 6;
    line-height: 32px;
    font-size: 20px;
  `}
`
const Requirements = styled.div`
  grid-column: 1 / span 4;

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 32px;

    strong {
      display: block;
      margin-top: 15px;
    }
  }
  p:last-of-type {
    padding-bottom: 100px;
  }
  ${media.tablet`
    grid-column: 7 / span 7;
    font-size: 20px;
  `}
`
const ToggleWrapper = styled(GridContainer)`
  align-items: baseline;
`

const TitleWrapper = styled.button`
  text-align: left;
  grid-column: 1 / span 3;
  background: none;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  cursor: pointer;
  outline: inherit;
  margin-left: 0px;

  :hover {
    color: ${props => props.theme.red};
  }
`
const Title = styled.h1`
  font-weight: normal;
  font-family: StanleyRegular, serif;
  font-size: 30px;
  margin: 0 0 0 0;

  ${media.tablet`
    white-space: pre;
    font-size: 45px;
  `}
`
const ToggleButton = styled.div`
  grid-column: 4 / span 1;
  margin-left: 30px;
  ${media.tablet`
  grid-column: 12 / span 1;
  `}
`

export const JobPosting = ({
  title,
  description,
  requirements,
  active = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(active)

  return (
    <>
      <ToggleWrapper>
        <RuleLine />
        <TitleWrapper onClick={() => setIsExpanded(!isExpanded)}>
          <Title>{title}</Title>
        </TitleWrapper>
        <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <MinusIcon /> : <PlusIcon />}
        </ToggleButton>
      </ToggleWrapper>

      <SectionWrapper show={isExpanded}>
        <Section>{description}</Section>
        <Requirements dangerouslySetInnerHTML={{ __html: requirements }} />
      </SectionWrapper>
    </>
  )
}
